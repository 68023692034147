//  $.fi.isResponsive = true;
var FI = !FI ? {} : FI;
FI.editMode = false;
FI.pageId = 520;
FI.adminLoggedIn = false;
FI.languageId = 1;

function ShowScreen() {
  var WindowHeight = jQuery(window).height();
  jQuery("#HideScreen").css("height", jQuery(document).height() + "px");
  jQuery("#HideScreen").css("display", "");

  var paddingTop = parseInt(WindowHeight) / parseInt(2);
  jQuery("#HideScreen img").css("padding-top", paddingTop + "px");
  var $boot = $(".percentage"),
    $bootInner = $(".presentage-in"),
    cnt = 0,
    interval = 1,
    bootDelay = setInterval(function () {
      cnt += interval;
      $bootInner.text(cnt + "%");
      if (cnt === 100) {
        $("html").removeClass("has-loader");
        $boot.remove();
        clearTimeout(bootDelay);
      }
    }, 0);

  HideScreen();
}

function HideScreen() {
  setTimeout(function () {
    jQuery("#HideScreen").animate({ opacity: "0" }, 700);
    jQuery("#HideScreen").hide(500);
    jQuery("#viewport").animate({ opacity: "1" }, 700);
  }, 1000);
}

$(document).ready(function () {
  // $('.multiselect-ui').multiselect({
  //     includeSelectAllOption: true
  // });

  $("#HideScreen").append(
    '<div class="percentage"><div class="presentage-in"></div></div>'
  );

  ShowScreen();

  setTimeout(function () {
    $(".c-mobile-menu").on("click", function (ev) {
      ev.preventDefault();
      $(".header").addClass("open-menu");
    });
    $(".c-mobile-close-menu").on("click", function (ev) {
      ev.preventDefault();
      $(".header").removeClass("open-menu");
    });
  }, 1000);

  var scrollToTopBtn = document.querySelector(".scrollToTopBtn");
  var rootElement = document.documentElement;

  setTimeout(function () {
    $(document).find(".support-entities").addClass("plz-reduce");

    $(".support-entities").owlCarousel({
      margin: 10,
      navText: [
        '<i class="fi-arrow-thick-left"></i>',
        '<i class="fi-arrow-thick-right"></i>',
      ],
      rtl: Lang == "ar" ? true : false,
      nav: true,
      items: 3,
      loop: true,
      slideBy: 1,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,

      responsive: {
        320: {
          items: 1,
          slideBy: 1,
        },
        560: {
          items: 1,
          slideBy: 1,
        },
        768: {
          items: 2,
          slideBy: 2,
        },
        1024: {
          items: 3,
          slideBy: 3,
        },
      },
    });
  }, 1500);

  function handleScroll() {
    // Do something on scroll
    var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight;
    if (rootElement.scrollTop / scrollTotal > 0.8) {
      // Show button
      scrollToTopBtn.classList.add("showBtn");
    } else {
      // Hide button
      scrollToTopBtn.classList.remove("showBtn");
    }
  }

  function scrollToTop() {
    // Scroll to top logic

    rootElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
});

//Navigation Bar Fixed
var scrolled = false;
$(window).scroll(function () {
  var windowSize = $(window).width() > 800;
  if (windowSize) {
    var nav = $(".main-menu .navigation");
    var primaryNavigation = $(".main-menu .navigation .primary-navigation");

    var firstChild = nav.find(".first.no-child");

    if ($(window).scrollTop() > 121 && !scrolled) {
      nav
        .css({ top: "-30px" })
        .addClass("nav-fixed")
        .animate({ top: "0px" }, 500);
      primaryNavigation.addClass("container");
      firstChild.css({ top: "-50px" }).delay(100).animate({ top: "0" });
      scrolled = true;
    }

    if ($(window).scrollTop() < 121 && scrolled) {
      nav.animate({ top: "0" }, 100);
      nav.removeClass("nav-fixed");
      primaryNavigation.removeClass("container");

      scrolled = false;
    }
  }
});
// Navigation fixed
